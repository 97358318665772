/* #region Imports and Uses */
@use "@angular/material" as mat;
@use "@angular/material-experimental" as matx;
@use "sass:map";
@use "theme/basic/mixin" as *;
@use "theme/basic/typography";
@use "theme/basic/variables" as vars;
@use "theme/cp2-palette.scss" as cp2;
@use "theme/dialog";
@use "theme/formio.scss";
@use "theme/helper-casses.scss";
@use "theme/material-components/orga-tabs";
@use "theme/material-components/workflow-tabs";
@use "theme/pages/home-patient-task";
@use "theme/pages/home-patient-visite";
@use "theme/pages/orga";
@use "theme/pages/patient-dialog";
@use "theme/pages/patient-list";
@use "theme/pages/start-popup";
@use "theme/pages/workflow";
@use "theme/pages/setting";
@use "theme/rostock-palette.scss" as rostock;

@import "@material-design-icons/font/filled.css";
@import "@material-design-icons/font/outlined.css";
@import "gridstack/dist/gridstack.css";
@import "gridstack/dist/gridstack-extra.css";

/* #endregion */

/* #region Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Style to fix the weird border with Angular Material */
.mdc-notched-outline__notch {
    border-right: none !important;
}

/* #endregion */

/* #region Angular Material Themes */

/* Minimal Theme */
$minimal-primary: mat.define-palette(mat.$grey-palette);
$minimal-accent: mat.define-palette(mat.$grey-palette);
$minimal-warn: mat.define-palette(mat.$red-palette);
$minimal-theme: mat.define-light-theme((
    color: (
        primary: $minimal-primary,
        accent: $minimal-accent,
        warn: $minimal-warn,
    ),
));

@include mat.core();
@include mat.all-component-themes($minimal-theme);

/* CP2 Theme */
$cp2-theme: matx.define-theme((
    color: (
        theme-type: light,
        primary: cp2.$custom-palette,
    ),
));

$cp2-dark-theme: matx.define-theme((
    color: (
        theme-type: dark,
        primary: cp2.$dark-palette,
    ),
));

$cp2-rostock-theme: matx.define-theme((
    color: (
        theme-type: light,
        primary: rostock.$custom-palette,
    ),
));

@include mat.core();

/* CP2 Light Theme */
.cp2-theme {
    @include matx.color-variants-back-compat($cp2-theme);
    @include mat.all-component-themes($cp2-theme);
    --mat-dialog-container-max-width: 100%;
    --mdc-dialog-container-shape: 8px; // border-radius for dialogs
    --mdc-filled-button-container-color: var(--primary-color);
    --mat-menu-container-color: var(--color-white);
    --primary-color: #{mat.get-color-from-palette(cp2.$custom-palette, 40)};
    --secondary-color: #{mat.get-color-from-palette(cp2.$custom-palette, 80)};
    --danger-color: #{mat.get-color-from-palette(cp2.$private-error-palette, 30)};

    --color-card-primary-color: #{mat.get-color-from-palette(cp2.$custom-palette, 90)};
    --color-background-primary-color: #{mat.get-color-from-palette(cp2.$custom-palette, 98)};
    --color-background-primary-color-hover: #{mat.get-color-from-palette(cp2.$custom-palette, 80)};
    --color-background-primary-list-color: #{mat.get-color-from-palette(cp2.$custom-palette, 95)};
    --color-link-primary-color: #{mat.get-color-from-palette(cp2.$custom-palette, 10)};

    /* #region Table Custom Properties */
    --color-table-primary-color: #{mat.get-color-from-palette(cp2.$custom-palette, 98)};
    --color-background-table-line-odd: #{mat.get-color-from-palette(cp2.$custom-palette, 90)};
    /* #endregion */

    --color-white: #{mat.get-color-from-palette(cp2.$custom-palette, 100)};
    --formio-text-color: #{mat.get-color-from-palette(cp2.$custom-palette, 0)};
    --formio-default-background-color: #{mat.get-color-from-palette(cp2.$custom-palette, 100)};
    --formio-button-background-color: #{mat.get-color-from-palette(cp2.$custom-palette, 40)};
    --formio-button-border-radius: 50vh;
    --formio-button-text-color: #{mat.get-color-from-palette(cp2.$custom-palette, 100)};
    --formio-input-field-border: 1px solid #{mat.get-color-from-palette(cp2.$custom-palette, 80)};
    --formio-danger-button-background-color: #{mat.get-color-from-palette(cp2.$private-error-palette, 30)};
    --formio-disabled-button-background-color: #{mat.get-color-from-palette(cp2.$custom-palette, 80)};
    --formio-title-text-color: #{mat.get-color-from-palette(cp2.$custom-palette, 0)};

    --formio-secondary-button-background-color: #{mat.get-color-from-palette(cp2.$custom-palette, 80)};
    --formio-secondary-button-color: #{mat.get-color-from-palette(cp2.$custom-palette, 40)};
}

/* CP2 Dark Theme */
.cp2-dark-theme {
    @include matx.color-variants-back-compat($cp2-dark-theme);
    @include mat.all-component-themes($cp2-dark-theme);
    --mat-dialog-container-max-width: 100%;
    --mdc-filled-button-container-color: var(--primary-color);
    --mat-menu-container-color: var(--color-white);

    --primary-color: #{mat.get-color-from-palette(cp2.$dark-palette, 40)};
    --secondary-color: #{mat.get-color-from-palette(cp2.$dark-palette, 80)};
    --danger-color: #{mat.get-color-from-palette(cp2.$private-error-palette, 30)};

    --color-card-primary-color: #{mat.get-color-from-palette(cp2.$dark-palette, 95)};
    --color-background-primary-color: #{mat.get-color-from-palette(cp2.$dark-palette, 90)};
    --color-link-primary-color: #{mat.get-color-from-palette(cp2.$dark-palette, 10)};
    --color-table-primary-color: #{mat.get-color-from-palette(cp2.$dark-palette, 98)};

    --color-white: #{mat.get-color-from-palette(cp2.$dark-palette, 100)};

    /* #region Formio Custom Properties */
    --formio-text-color: #{mat.get-color-from-palette(cp2.$dark-palette, 0)};
    --formio-default-background-color: #{mat.get-color-from-palette(cp2.$dark-palette, 100)};
    --formio-button-background-color: #{mat.get-color-from-palette(cp2.$dark-palette, 40)};
    --formio-button-border-radius: 50vh;
    --formio-button-text-color: #{mat.get-color-from-palette(cp2.$dark-palette, 100)};
    --formio-input-field-border: 1px solid #{mat.get-color-from-palette(cp2.$dark-palette, 80)};
    --formio-danger-button-background-color: #{mat.get-color-from-palette(cp2.$private-error-palette, 30)};
    --formio-disabled-button-background-color: #{mat.get-color-from-palette(cp2.$dark-palette, 80)};
    --formio-title-text-color: #{mat.get-color-from-palette(cp2.$dark-palette, 0)};

    --formio-secondary-button-background-color: #{mat.get-color-from-palette(cp2.$dark-palette, 80)};
    --formio-secondary-button-color: #{mat.get-color-from-palette(cp2.$dark-palette, 40)};
    /* #endregion */
}

/* CP2 Rostock Theme */
.cp2-rostock-theme {
    @include matx.color-variants-back-compat($cp2-rostock-theme);
    @include mat.all-component-themes($cp2-rostock-theme);
    --mat-dialog-container-max-width: 100%;
    --mdc-filled-button-container-color: var(--primary-color);
    --mat-menu-container-color: var(--color-white);
    --primary-color: #{mat.get-color-from-palette(rostock.$custom-palette, 40)};
    --secondary-color: #{mat.get-color-from-palette(rostock.$custom-palette, 80)};

    --color-card-primary-color: #{mat.get-color-from-palette(rostock.$custom-palette, 90)};
    --color-background-primary-color: #{mat.get-color-from-palette(rostock.$custom-palette, 95)};
    --color-background-primary-color-hover: #{mat.get-color-from-palette(rostock.$custom-palette, 80)};
    --color-link-primary-color: #{mat.get-color-from-palette(rostock.$custom-palette, 10)};

    /* #region Table Custom Properties */
    --color-table-primary-color: #{mat.get-color-from-palette(rostock.$custom-palette, 98)};
    --color-background-table-line-odd: #{mat.get-color-from-palette(rostock.$custom-palette, 90)};
    /* #endregion */

    --color-white: #{mat.get-color-from-palette(rostock.$custom-palette, 100)};

    /* #region Formio Custom Properties */
    --formio-text-color: #{mat.get-color-from-palette(rostock.$custom-palette, 0)};
    --formio-default-background-color: #{mat.get-color-from-palette(rostock.$custom-palette, 100)};
    --formio-button-background-color: #{mat.get-color-from-palette(rostock.$custom-palette, 40)};
    --formio-button-border-radius: 50vh;
    --formio-button-text-color: #{mat.get-color-from-palette(rostock.$custom-palette, 100)};
    --formio-input-field-border: 1px solid #{mat.get-color-from-palette(rostock.$custom-palette, 80)};
    --formio-disabled-button-background-color: #{mat.get-color-from-palette(rostock.$custom-palette, 80)};
    --formio-title-text-color: #{mat.get-color-from-palette(rostock.$custom-palette, 0)};

    --formio-secondary-button-background-color: #{mat.get-color-from-palette(rostock.$custom-palette, 80)};
    --formio-secondary-button-color: #{mat.get-color-from-palette(rostock.$custom-palette, 40)};
    /* #endregion */
}

/* #endregion */

/* #region Global Styles */
html,
body {
    position: fixed; /* Prevents scrolling */
    overflow: hidden; /* Hide scrollbars */
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    /* Safe area */
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

/* #region Dialog Styles */

/* Size M- Styles for iPad Mini (1180px x 820px) */
@media (min-width: 768px) and (max-width: 1023.9px) {
    .home-visite-dialog,
    .home-visite-list-dialog,
    .home-task-list-dialog,
    .home-task-dialog {
        &.home-visite-dialog {
            width: 500px;
            height: 350px;
        }

        &.home-visite-list-dialog {
            width: 500px;
            height: 350px;
        }

        &.home-task-list-dialog {
            width: 700px;
            height: 500px;
        }

        &.home-task-dialog {
            width: 700px;
            height: 500px;
        }
    }
}

/* Size M Styles for iPad Pro (1366px x 1024px) */
@media (min-width: 1024px) and (max-width: 1366.9px) {
    .home-visite-dialog,
    .home-visite-list-dialog,
    .home-task-list-dialog,
    .home-task-dialog {
        &.home-visite-dialog {
            width: 578px;
            height: 437px;
        }

        &.home-visite-list-dialog {
            width: 578px;
            height: 437px;
        }

        &.home-task-list-dialog {
            width: 810px;
            height: 642px;
        }

        &.home-task-dialog {
            width: 810px;
            height: 642px;
        }
    }
}

/* Size M Styles for Desktop (1920px x 1080px) */
@media (min-width: 1367px) and (max-width: 1920px) {
    .home-visite-dialog,
    .home-visite-list-dialog,
    .home-task-list-dialog,
    .home-task-dialog {
        &.home-visite-dialog {
            width: 813px;
            height: 460px;
        }

        &.home-visite-list-dialog {
            width: 813px;
            height: 460px;
        }

        &.home-task-list-dialog {
            width: 1138px;
            height: 658px;
        }

        &.home-task-dialog {
            width: 1138px;
            height: 658px;
        }
    }
}

/* #endregion */

/* #region Responsive Styles */
@media (max-width: 599.98px) and (orientation: portrait),
(max-width: 959.98px) and (orientation: landscape) {
    .sidenav-container {
        .mat-drawer-inner-container {
            overflow-y: auto !important;
        }
    }
}

/* #endregion */

/* #region Material Components Overrides */

/* Hide Form Field Subscript */
.mat-mdc-form-field-subscript-wrapper {
    display: none;
}

/* Overlay Pane Styles */
.cdk-overlay-pane {
    align-items: center;

    /* Adjust dropdown panel widths */
    &:has(.mat-mdc-select-panel) {
        min-width: fit-content !important;
    }
}

/* Table Header Row */
.mat-mdc-header-row {
    border-bottom: 2px solid #cac4cf !important;
    height: auto !important;
}

/* Select Panel Background */
.mat-mdc-select-panel {
    background-color: var(--color-white) !important;
}

/* Date Picker Panel Background */
.cp2-theme .mat-primary.mat-datepicker-content {
    --mat-datepicker-calendar-container-background-color: var(--color-white);
}

/* Remove Scrollbar in Date Picker */
.cdk-overlay-container {
    .cdk-overlay-pane {
        overflow: hidden;
    }
}

/* Dialog Surface Border Radius */
.mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 12px !important;
}

/* Snackbar Styles */
.snackbar-danger {
    --mdc-snackbar-container-color: #c0392b;
}

.mat-mdc-snack-bar-container {
    &.success-snackbar {
        --mdc-snackbar-container-color: #2ecc71;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }

    &.warning-snackbar {
        --mdc-snackbar-container-color: #f1c40f;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }

    &.error-snackbar {
        --mdc-snackbar-container-color: #c0392b;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }
}

/* Tab Styles */
.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: var(--color-white) !important;
}

.mdc-tab--active {
    background-color: var(--primary-color) !important;
}

.mat-icon {
    height: unset !important;
    width: unset !important;
}

/* Dynamic List Padding */
app-dynamic-list {
    .mdc-list {
        padding: 2px 0;
    }
}

/* Flex Utilities */
.lbFlex {
    display: flex;
}

.lbCol {
    flex-direction: column;
}

.lbGapSM {
    gap: 1em;
}

.lbMainText {
    font-size: 1em;
    color: #1c1f20;
}

.lbSubText {
    font-size: 0.9em;
    color: #636e72;
}

.lbFlexAlignCenter {
    align-items: center;
}

/* Spacer */
.spacer-xy {
    flex: 1 1 auto;
}

/* Image Content */
.image-content {
    max-height: 100vh;
    height: auto;
    width: 100%;
    overflow: hidden !important;
    display: block;
    margin: auto;
}

.canvas-container {
    width: 100% !important;
}

.ng2-pdf-viewer-container {
    overflow-x: hidden !important;

    .page {
        height: auto !important;

        .canvasWrapper {
            background-color: #eadeff;
            height: auto !important;

            canvas {
                min-width: 35rem;
                width: 100% !important;
                max-width: 50rem;
                height: fit-content !important;
                margin: auto !important;
            }
        }
    }
}

/* Barcode Scanner Styles */
body.barcode-scanner-active {
    visibility: hidden;
    --background: transparent;
}

.barcode-scanner-modal {
    visibility: visible;

    @media (prefers-color-scheme: dark) {
        --background: transparent;
    }
}

/* Button Colors */
button.mat-primary {
    @include mat.button-color($cp2-theme, $color-variant: primary);
}

button.mat-secondary {
    @include mat.button-color($cp2-theme, $color-variant: secondary);
}

button.mat-danger {
    @include mat.button-color($cp2-theme, $color-variant: error);
}

.dropdownBorder {
    border-width: 1px;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

/* #endregion */

/* #region Root Variables */
:root {
    --color-gray: #636e72;
    --sidebar-width: 64px;
}

/* #endregion */

/* #region Shape Angular Material Components */
.mat-drawer-inner-container {
    overflow-x: hidden !important;
}

/* #endregion */

/* #region Snackbar Styles */
.mat-mdc-snack-bar-container {
    &.success-snackbar,
    &.warning-snackbar,
    &.error-snackbar {
        /* Shared styles can be added here if needed */
    }
}

/* #endregion */

/* #region PDF Viewer Styles */
.ng2-pdf-viewer-container {
    overflow-x: hidden !important;

    .page {
        height: auto !important;

        .canvasWrapper {
            background-color: #eadeff;
            height: auto !important;

            canvas {
                min-width: 35rem;
                width: 100% !important;
                max-width: 50rem;
                height: fit-content !important;
                margin: auto !important;
            }
        }
    }
}

/* #endregion */

/* #region Typography */
.table-name {
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 500;
    line-height: 1.5;
}

.table-text {
    font-family: "Roboto", sans-serif;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.5;
}

.table-subtext {
    font-family: "Roboto", sans-serif;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.8;
}

.table-date {
    font-family: "Roboto", sans-serif;
    font-size: 0.75em;
    font-weight: 400;
    line-height: 2;
}

.h1 {
    font-family: "Roboto", sans-serif;
    font-size: 2em;
    font-weight: 700;
    line-height: 1.2;
    margin: 20px 0;
}

.h2 {
    font-family: "Roboto", sans-serif;
    font-size: 1.875em;
    font-weight: 700;
    line-height: 1.2;
    margin: 18px 0;
}

.h3 {
    font-family: "Roboto", sans-serif;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.2;
    margin: 16px 0;
}

.h4 {
    font-family: "Roboto", sans-serif;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.2;
    margin: 14px 0;
}

.h5 {
    font-family: "Roboto", sans-serif;
    font-size: 1.125em;
    font-weight: 700;
    line-height: 1.2;
    margin: 12px 0;
}

.h6 {
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 500;
    line-height: 1;
    margin: 6px 0;
}

.p {
    font-family: "Roboto", sans-serif;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    margin: 10px 0;
}

/* #endregion */

/* #region Miscellaneous Styles */

/* Show the icon from date time picker component */
.mdc-text-field__input::-webkit-calendar-picker-indicator {
    display: block !important;
}

/* Make all the width of all the dropdown panel/select-panel will not change because of the length of the text */
.cdk-overlay-pane:has(.mat-mdc-select-panel) {
    min-width: fit-content !important;
}

/* Remove the scrollbar in the date picker */
.cdk-overlay-container {
    .cdk-overlay-pane {
        overflow: hidden;
    }
}

/* Make the height of all the table shorter */
.mat-mdc-header-row {
    height: auto !important;
}

/* Change padding for all the mat list in all dynamic list */
app-dynamic-list {
    .mdc-list {
        padding: 2px 0;
    }
}

/* Mat-table: add a line under the header in the table for all the component */
.mat-mdc-header-row {
    border-bottom: 2px solid #cac4cf !important;
}

/* Mat-select: change all the bg color for all the dropdown panel */
.mat-mdc-select-panel {
    background-color: var(--color-white) !important;
}

/* Change the bg color of the date picker panel */
.cp2-theme .mat-primary.mat-datepicker-content {
    --mat-datepicker-calendar-container-background-color: var(--color-white);
}

/* Mat-tab: change the selected tab's text's color for all the components */
.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: var(--color-white) !important;
}

.mdc-tab--active {
    background-color: var(--primary-color) !important;
}

/* Mat-dialog-container styles */
.mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 12px !important;
}

/* Expose app colors */
:root {
    --color-gray: #636e72;
    --sidebar-width: 64px;
}

/* #endregion */

/* #region Barcode Scanner Styles */
body.barcode-scanner-active {
    visibility: hidden;
    --background: transparent;
}

.barcode-scanner-modal {
    visibility: visible;

    @media (prefers-color-scheme: dark) {
        --background: transparent;
    }
}

/* #endregion */
